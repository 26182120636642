<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.report.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self, 'pdf', module, 'Report')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>

          <v-server-table :columns="columns" url="/entity/report/users" :options="options" ref="myTable"
                          @loaded="exports(self, 'Excel', module, 'Report')">

            <template #name="data">
              <Avatar v-if="data.row.type==='Individual'"
                      :image="data.row.avatar"
                      :defaultImage="data.row.avatar"
                      :content="data.row.name"
                      :isProfileListPage="true"
                      :length="50"
              />
              <span v-else> <center> --- </center>  </span>
            </template>

            <template #company="data">
              <Avatar v-if="data.row.type==='Company'"
                      :image="data.row.avatar"
                      :defaultImage="data.row.avatar"
                      :content="data.row.company"
                      :isProfileListPage="true"
                      :length="50"
              />
              <span v-else> <center> --- </center>  </span>
            </template>

            <template #role="data">
              <span :title="data.row.role" v-if="data.row.role" >{{ trimfunction(data.row.role, 50) }}</span>
              <span v-else >Member</span>
            </template>

            <template #actions="data">
              <CButton color="warning" v-c-tooltip="$lang.buttons.general.crud.view"
                       v-on:click="viewTableRow(data.row.id)">
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {users} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "@/components/Avatar";
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "BranchReport",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
    Avatar
  },
  data() {
    return {
      json_data: [],
      json_label: {},
      messageColor: "success",
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      module: users,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      activePage: 1,
      columns: ["role","firstName", "company", "name",  "actions"],
      data: [],
      options: {
        headings: {
          type: this.$lang.report.table.type,
          firstName: this.$lang.report.table.firstName,

          company: this.$lang.report.table.company,
          name: this.$lang.report.table.name,

          role: this.$lang.report.table.role,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["firstName", "company", "role"],
        sortable: ["firstName", "company", "role"],
        filterable: ["firstName", "company", "role"],
      },
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    store.commit("showLoader", false);
  },
  methods: {
    viewTableRow(item) {
      this.$router.push({path: `reporting/view/${item}`});
    },
  },
};
</script>
